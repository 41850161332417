/* istanbul ignore file */
import { createTheme } from "@mui/material";

declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"];
  }
  interface PaletteOptions {
    neutral: PaletteOptions["primary"];
  }
}

export const theme = createTheme({
  palette: {
    background: {
      default: "#f1f1f1",
    },
    primary: {
      main: "#001489",
    },
    secondary: {
      main: "#595a5e",
    },
    neutral: {
      main: "#f2f2f2",
    },
  },
  typography: {
    fontFamily: ["VerlagSSm"].join(","),
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            backgroundColor: "#F2F2F2",
          },
          borderRadius: "0!important",
        },
      },
    },
  },
});

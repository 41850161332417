import { ReactNode } from "react";

export interface FormFieldLabelProps {
  children: ReactNode;
  isRequired?: boolean;
}

export const FormFieldLabel = (props: FormFieldLabelProps) => {
  const { isRequired, children } = props;

  return (
    <span>
      {isRequired && <span style={{ color: "red" }}>* </span>}
      {children}
    </span>
  );
};

import React from "react";
import { ButtonProps } from "@mui/material/Button"
import { StyledButton } from "./index.style";

const Button = (props: ButtonProps) => {
    return (
        <StyledButton { ...props } />
    );
}

export default Button;
import { Alert, Collapse } from "@mui/material";
import { useEffect, useState } from "react";

export type AlertMessageProps = {
  message: string;
  show: boolean;
};

export const AlertMessage = (props: AlertMessageProps) => {
  const { show = false, message } = props;
  const [display, setDisplay] = useState(show);

  useEffect(() => {
    const timeId = setTimeout(() => {
      setDisplay(false);
    }, 2000);

    return () => {
      clearTimeout(timeId);
    };
  }, []);

  return (
    <Collapse in={display}>
      <Alert severity="success" sx={{ mt: "20px" }}>
        {message}
      </Alert>
    </Collapse>
  );
};

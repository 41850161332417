import { styled } from "@mui/material/styles";
import PersonOutline from '@mui/icons-material/PersonOutline';

export const StyledPersonOutline = styled(PersonOutline)`
    cursor: pointer;
`;

export const MenuWrapper = styled('span')`
    position: relative;
`;

export const MenuDiv = styled('div')`
    display: none;
    position: absolute;
    top: 12px;
    left: -26px;
    padding: 15px;
    width: 100px;
    background-color: ${(props: any) => props.theme.palette.primary.main};
    text-align: center;
    
    &.opened {
        display: block;
    }
    
    div {
        width: 100%;
        cursor: pointer;
        
        &:hover {
            /* font-weight: bold; */
            background-color: rgba(0, 0, 0, 0.5);
        }
    }
`;
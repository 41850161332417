import { styled } from "@mui/material/styles";
import { Divider } from "@mui/material";

export const CardContent = styled('div')`
    background: white;
`;

export const StyledDivider = styled(Divider)`
    margin: revert;  
    height: 1px;
    width: 100%;
`;